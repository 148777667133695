import { config } from "variables/config";
import StatsCardDemande from "./StatsCardDemandes";
import useDataFetching from "hooks/useDataFetching";
import DemandesList from "./DemandesList";

const Demandes  =()=>{

    const [loadingCOntracts, errorsC, contracts, getContracts] = useDataFetching(
        `${config.app.api_url}/contracts/query`
    );

    return(
        <div>
            <StatsCardDemande/>

            <DemandesList compact={true} />
        </div>
    )

}

export default Demandes;
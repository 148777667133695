import { CircularProgress } from "@mui/material";
import { getValue } from "@testing-library/user-event/dist/utils";
import useDataFetching from "hooks/useDataFetching";
import { useEffect, useState } from "react";
import { formatDate } from "utils";
import { config } from "variables/config";

const StatsCardDemande = () => {

    const [loadingStats, errors, contractStats, getStats] = useDataFetching(
        `${config.app.api_url}/contracts/stats-details`
    );

    const Card = ({ label, value, loading }) => {

        return (
            <div class="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    {label}
                </dt>
               {loading ? <CircularProgress className="mx-auto" color="success" /> : <dd class="order-1 text-4xl font-extrabold text-green-500">{value}</dd>}
            </div>
        )
    }
    const getValue = (value) => {
        return value == null || value == undefined ? "0" :  value
    }
    return (
        <>
        
            <div class="bg-gray-50 py-4 ">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="max-w-7xl mx-auto text-center">
                        <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                            Demandes
                        </h2>
                        <p class="mt-3 text-xl text-gray-500 sm:mt-2">
                        Statistiques sur les demandes.
                        
                        </p>
                    </div>
                </div>
                <div class="mt-5 pb-1">
                    <div class="relative">
                        <div class="absolute inset-0 h-1/2 bg-gray-50"></div>
                        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div class="max-w-7xl mx-auto">
                                <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-5">
                                    <Card loading={loadingStats} label={"New Enterprises collected"} value={getValue(contractStats?.data?.total)} />
                                    <Card loading={loadingStats} label={"Demandes en attente"} value={getValue(contractStats?.data?.pending)} />
                                    {/* <Card label={"Enterprise Coverage"} value={((statsEnterTotalP?.data?.visited / statsEnterTotalP?.data?.total) * 100)?.toFixed(2) + "%"} /> */}
                                    <Card loading={loadingStats}  label={"Demandes evaluees"} value={getValue(contractStats?.data?.evaluated)} />
                                    <Card loading={loadingStats} label={"Demandes disponibles"} value={getValue(contractStats?.data?.available)} />
                                    <Card loading={loadingStats} label={"Demandes retirees"} value={getValue(contractStats?.data?.taken)} />
                                    {/* <Card loading={loadingStats} label={"Demandes refusees"} value={getValue(contractStats?.data?.rejected)} /> */}

                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default StatsCardDemande;
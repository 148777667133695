import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import DataTables from "views/admin/tables";
import RTLDefault from "views/rtl/default";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdGroups,
  MdWork,
  MdPeopleAlt,
  MdPersonPin,
  MdAppBlocking,
  MdAddChart,
  MdMap,
  MdCarRental,
  MdTask,
  MdAccessTime,
  MdChargingStation,
  MdAreaChart,
  MdFormatAlignCenter,
  MdSettingsCell,
  MdAccountTree,
  MdReadMore,
} from "react-icons/md";
// import Employes from "views/admin/employes";
import Regions from "views/admin/regions";
import MapView from "views/admin/map";
import Comptes from "views/admin/comptes/Comptes";
import GlobalMap from "views/admin/map/GlobalMap";
import Roles from "views/admin/roles/Roles";
import UserEnterprises from "views/admin/userEnterprises";
import UserEmployes from "views/admin/userEmployes";
import AdminComptes from "views/admin/adminCompte/AdminComptes";
import { FiMapPin } from "react-icons/fi";
import { FaGrinAlt, FaGrinHearts, FaMapMarkedAlt } from "react-icons/fa";
import { RiRoadMapFill } from "react-icons/ri";
import { BsGear, BsPersonFillGear } from "react-icons/bs";
import EnterprisesByUsers from "views/admin/tables/enterpriseByUsers";
import EmployersByUsers from "views/admin/tables/EmployersByUsers";
import EmployeList from "views/admin/employes/EmployeList";
import TabBar from "views/admin/default/components/TabBar";
import EmployesByEnterprise from "views/admin/employes/EmployesByEnterprise";
import Missions from "views/admin/Missions/Mission";
import Zones from "views/admin/zone/Zones";
import Tasks from "views/admin/Tasks/Tasks";
import AddEnterprise from "views/admin/tables/AddEnterprise";
import Reports from "views/admin/reports/Reports";
import Activities from "views/admin/reports/Activities";
import Notifications from "views/admin/notification/Notifications";
import EnterpriseAcccount from "views/admin/adminCompte/EnterpriseAcccount";
import EnterpriseAccountDetails from "views/admin/adminCompte/EnterpriseAccountDetails";
import Requests from "views/admin/adminCompte/Requests/Requests";
import RequestsAccount from "views/admin/adminCompte/Requests/RequestsAccount";
import AddEnterprisePage from "views/admin/tables/AddEnterprisePage";
import EmailVerification from "views/auth/EmailVerification";
import Demandes from "views/admin/demandes/Demandes";

export const authRoutes = [
  {
    name: "Login",
    layout: "/auth",
    path: "sign-in",
    component: <SignIn />,
  },
  {
    name: "Login",
    layout: "/auth",
    path: "email-verification",
    component: <EmailVerification />,
  },
]
const routes = [ 
  {
    name: "Tableau de bord",
    layout: "/admin",
    path: "stats",
    icon: <MdBarChart className="" size={25} />,
    displayOnSideBar : true,
    permissionName: "Voir menu statistiques",
    component: <TabBar />,
    roles : ['ADMIN','DGSN','MINREX','QUALITY']
  },
  // {
  //   name: "Activities",
  //   layout: "/admin",
  //   path: "reports",
  //   icon: <MdAccessTime className="h-6 w-6" />,
  //   permissionName: "Les activitees",
  //   component: <>Activites</>,
  //   roles : ['ADMIN','DGSN','MINREX','QUALITY']
  // },
  {
    name: "Activities",
    layout: "/admin",
    icon: <MdAreaChart className="h-6 w-6" />,
    // permissionName: " ",
    displayOnSideBar : true,

    path: "activities",
    component: <Activities/>,
    roles : ['ADMIN','QUALITY']
    
  },
  {
    name: "Demandes",
    layout: "/admin",
    icon: <MdReadMore className="h-6 w-6" />,
    // permissionName: " ",
    displayOnSideBar : true,

    path: "demandes",
    component: <Demandes/>,
    roles : ['ADMIN','QUALITY']
    
  },
  {
    name: "Reports",
    layout: "/admin",
    icon: <MdAddChart className="h-6 w-6" />,
    // permissionName: " ",
    path: "reports",
    displayOnSideBar : false,
    component: <Reports/>,
    roles : ['ADMIN','QUALITY']
    
    
  },
  // {
  //   name: "Dashboard",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <MdHome className="h-6 w-6" />,
  //   permissionName: "Voir menu statistiques",
  //   component: <MainDashboard />,
  //   roles : ['ADMIN','DGSN','MINREX']
  // },
  // {
  //   name: "Maps",
  //   layout: "/admin",
  //   path: "enterprises/:enterpriseID",
  //   icon: <RiRoadMapFill className="h-6 w-6" />,
  //   permissionName: "Detail entreprise",
  //   component: <AddEnterprise />,
  //   roles : ['ADMIN','DGSN','MINREX','QUALITY']
  // },
  // { 
  //   name: "Enterprises",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  // roles : ['AGENT','DGSN']
  //   secondary: true,
  // },
  {
    name: "Entreprises",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    permissionName: "Voir menu entreprise",
    displayOnSideBar : true,
    path: "enterprises",
    component: <DataTables />,
    roles : ['ADMIN','DGSN','MINREX','QUALITY']
    
  },
  {
    name: "Entreprises details",
    layout: "/admin",
    path: "enterprises/:id",
    component: <AddEnterprisePage  />,
    icon: <MdBarChart className="h-6 w-6" />,
    permissionName: "Voir menu entreprise",
    displayOnSideBar : false,
    roles : ['ADMIN','DGSN','MINREX','QUALITY']
    
  },
  
  {
    name: "Employés",
    layout: "/admin",
    icon: <MdPersonPin className="h-6 w-6" />,
    permissionName: "Voir menu employé",
    displayOnSideBar : true,
    path: "employes",
    component: <EmployeList />,
    roles : ['ADMIN','DGSN','MINREX','QUALITY']
    
  },
   
 
  {
    name: "Zones",
    layout: "/admin",
    icon: <MdMap className="h-6 w-6" />,
    displayOnSideBar : true,
    // permissionName: " ",
    path: "zones",
    component: <Zones/>,
    roles : ['ADMIN','QUALITY']
    
  },
  {
    name: "Missions",
    layout: "/admin",
    icon: <MdCarRental className="h-6 w-6" />,
    displayOnSideBar : true,
    // permissionName: " ",
    path: "missions",
    component: <Missions/>,
    roles : ['ADMIN','QUALITY']
    
  },
  {
    name: "Tâches",
    layout: "/admin",
    icon: <MdTask className="h-6 w-6" />,
    // permissionName: " ",
    displayOnSideBar : true,
    path: "tasks",
    component: <Tasks/>,
    roles : ['ADMIN','QUALITY']
    
  },
  
  {
    name: "EntreprisesUs",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "enterprises/:agentId",
    displayOnSideBar : false,
    component: <EnterprisesByUsers />,
    roles : ['ADMIN','DGSN','MINREX','QUALITY'],
    displayOnsideBar : false,
  },
  {
    name: "Employees",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "employees/agents/:agentId",
    displayOnSideBar : false,
    component: <EmployersByUsers />,
    roles : ['ADMIN','DGSN','MINREX','QUALITY'],
    displayOnsideBar : false,
  },
  {
    name: "Employees",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    permissionName: "Voir liste des employes",
    displayOnSideBar : false,
    path: "employees/:id",
    component: <EmployesByEnterprise />,
    roles : ['ADMIN','DGSN','MINREX','QUALITY']
  },
  {
    name: "Regions",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    displayOnSideBar : false,
    permissionName: "Voir liste regions",
    path: "region/:id",
    component: <Regions />,
    roles : ['ADMIN','DGSN','MINREX','QUALITY']
  },
 
  // {
  //   name: "Map Avancée",
  //   layout: "/admin",
  //   path: "global-map",
  //   icon: <FaMapMarkedAlt className="h-6 w-6" />,
  //   component: <GlobalMap />,
  //   roles : ['ADMIN','AGENT','DGSN']
  // },
  {
    name: "Comptes Agents",
    layout: "/admin",
    displayOnSideBar : true,
    path: "comptes",
    icon: <MdGroups className="h-6 w-6" />,
    permissionName: "Voir menu comptes agents",
    component: <Comptes />,
    roles : ['ADMIN','QUALITY']
  },
  {
    name: "Gestion des comptes",
    layout: "/admin",
    path: "GestionComptes",
    displayOnSideBar : true,
    icon: <BsPersonFillGear className="h-6 w-6" />,
    permissionName: "Voir menu gestion des comptes",
    component: <AdminComptes />,
    roles : ['ADMIN']
  },
  {
    name: "Agent Enterprises",
    layout: "/admin",
    path: "user/enterprises/:id",
    icon: <MdGroups className="h-6 w-6" />,
    displayOnSideBar : false,
    permissionName: "Entreprises enregistrées par un agent",
    component: <UserEnterprises />,
    roles : ['ADMIN','QUALITY']
  },
  {
    name: "Account enterprise",
    layout: "/admin",
    path: "account/enterprises",
    icon: <MdAccountTree className="h-6 w-6" />,
    displayOnSideBar : true,
    // permissionName: "Entreprises enregistrées par un agent",
    component: <EnterpriseAcccount />,
    roles : ['ADMIN','QUALITY']
  },
  // {
  //   name: "Detail Account enterprise",
  //   layout: "/admin",
  //   path: "account/enterprises/:id",
  //   icon: <MdGroups className="h-6 w-6" />,
  //   displayOnSideBar : false,
  //   // permissionName: "Entreprises enregistrées par un agent",
  //   component: <EnterpriseAccountDetails />,
  //   roles : ['ADMIN','QUALITY']
  // },
  {
    name: "Detail Account enterprise",
    layout: "/admin",
    path: "account/enterprises/:id/requests",
    icon: <MdGroups className="h-6 w-6" />,
    displayOnSideBar : false,
    // permissionName: "Entreprises enregistrées par un agent",
    component: <RequestsAccount />,
    roles : ['ADMIN','QUALITY']
  },
  {
    name: "Requetes",
    layout: "/admin",
    path: "requests",
    icon: <MdFormatAlignCenter className="h-6 w-6" />,
    displayOnSideBar : true,
    // permissionName: "Entreprises enregistrées par un agent",
    component: <Requests />,
    roles : ['ADMIN','QUALITY']
  },
  {
    name: "Agent Employees",
    layout: "/admin",
    path: "user/enterprises/employes/:userId/:id",
    displayOnSideBar : false,
    icon: <MdGroups className="h-6 w-6" />,
    permissionName: "Employés enregistrées par un agent",
    component: <UserEmployes />,
    roles : ['ADMIN','QUALITY']
  },
  {
    name: "Roles & Permissions",
    layout: "/admin",
    path: "roles",
    displayOnSideBar : true,
    icon: <BsGear  className="h-6 w-6" />,
    permissionName: "Voir menu gestion des roles",
    component: <Roles />,
    roles : ['ADMIN']
  },

  {name: "Notifications",
  layout: "/admin",
  path: "notifications",
  displayOnSideBar : true,
  icon: <BsGear className="h-6 w-6" />,
  permissionName: "Voir menu notifcations",
  component: <Notifications />,
  roles : ['ADMIN']
},
 

  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // roles : ['AGENT','DGSN']
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // roles : ['AGENT','DGSN']
  // },
];
export default routes;
import dayjs, { locale } from "dayjs";
import { useAuthUser } from "react-auth-kit";
import { downloadExcel } from "react-export-table-to-excel";
import relativeTime from 'dayjs/plugin/relativeTime';
import fr from "dayjs/locale/fr";

dayjs.extend(relativeTime);
export function CheckRole(r) {
  const auth = useAuthUser();

  return auth()?.user?.roles?.filter((role) => role?.name === r)?.length > 0 ? true : false;
}

export function capitalyse(str) {
  return (str?.length > 0 && typeof (str) === "string") ? str?.charAt(0).toUpperCase() + str?.slice(1)?.toLowerCase() : str
}


export function handleDownload({ filename, header, body }) {
  downloadExcel({
    fileName: filename,
    sheet: "react-export-table-to-excel",
    tablePayload: {
      header: header,
      // accept two different data structures
      body: body
    },
  });
}

export function checkIsValidDate(permitDate){
  // console.log("🚀 ~ checkIsValidPermit ~ permitDate:", permitDate)
  return new Date() < getExpiredPermitDate(permitDate)
}

export function checkIsValidPermit(employe) {
  const contract = getLastContract(employe?.contracts);
  // console.log("🚀 ~ checkIsValidPermit ~ contract:", contract)
  // console.log("🚀 ~ checkIsValidPermit ~ permitDate:", permitDate)
  return contract?.permitNumber != null && contract?.permitNumber != undefined && contract?.permitNumber?.length > 0 && new Date() < getExpiredPermitDate(contract?.permitDate)
}

export function checkIsValidContrat(contract) {
  // const contract = getLastContract(employe?.contracts);
  // console.log("🚀 ~ checkIsValidPermit ~ contract:", contract)
  // console.log("🚀 ~ checkIsValidPermit ~ permitDate:", permitDate)
  return contract?.permitNumber != null && contract?.permitNumber != undefined && contract?.permitNumber?.length > 0 && new Date() < getExpiredPermitDate(contract?.permitDate)
}

export function checkIsValidPermitDate(employe) {
  // console.log("🚀 ~ checkIsValidPermitDate ~ getLastContract(employe?.contracts):", getLastContract(employe?.contracts))
  // console.log("🚀 ~ checkIsValidPermit ~ permitDate:", permitDate)
  return new Date() < getExpiredPermitDate(getLastContract(employe?.contracts)?.permitDate)
}


export function checkIsValidContractDate(contract) {
  // console.log("🚀 ~ checkIsValidPermitDate ~ getLastContract(employe?.contracts):", getLastContract(employe?.contracts))
  // console.log("🚀 ~ checkIsValidPermit ~ permitDate:", permitDate)
  return new Date() < getExpiredPermitDate(contract?.permitDate)
}



export function getExpiredPermitDate(permitDate) {
  return permitDate ? new Date(new Date(permitDate).setFullYear(new Date(permitDate).getFullYear() + 2)) : null
  // return permitDate?.length > 0 ?  permitDate : '--'
}



export const formatNumber = (nombre) => {

  if (nombre != null && nombre != undefined) {

    return nombre.toLocaleString('us')
  }
  else {
    return '--'

  }
}


export function createMapLink(address) {
  // Encode any special characters in the address
  const encodedAddress = encodeURIComponent(address);
  // Build the URL with the address parameter
  const url = `https://maps.google.com/maps?q=${encodedAddress}`;
  return url;
}


export function formatDate(date) {
  if (date) {
    return dayjs(date).format("DD-MM-YYYY")
  }
}

export function formatDateFrom(date) {
  if (date) {
    
    return dayjs(date).fromNow({locale : fr})
  }
}

export function formatDateTime(date) {
  if (date) {
    return dayjs(date).format("ddd, MMM D, YYYY h:mm A")
  }
}



export function getLastContract(contracts) {
  if (contracts?.length < 1) {
    return null
  }
  return contracts?.sort((a, b) => new Date(b?.permitDate) - new Date(a?.permitDate))[0]

}
export function getFullName(u){
  if(u?.fullName) return u?.fullName
  else return `${(u?.firstName || '') + " "+ (u?.lastName || '')}`
}

export function isValidEmail(email) {
  if(!email) return false
  const regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  return regex.test(email);
}


export function castToRequestParams(params){
  const rr = Object.keys(params)?.map((key) => {
    // console.log(typeof params[key] == "string");
    // if(params[key]){
    return (params[key] != null && params[key] != undefined) ? key + "=" + params[key] : "-1";
    // }
  });
  return rr?.filter((r) => r !== "-1")?.join("&");
}

export function buildParams(params){

  if(!params) return null

  const rr = Object.keys(params)?.map((key) => {
    // console.log(typeof params[key] == "string");
    // if(params[key]){
    return (params[key] != null && params[key] != undefined) ? key + "=" + params[key] : "";
    // }
  });

  return rr?.filter((r) => r !== "-1")?.join("&")
}




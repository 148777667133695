import {
  Button,
  Card,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { MdAdd, MdMail } from "react-icons/md";

import useDataFetching from "hooks/useDataFetching";
import { config } from "variables/config";
import { useState } from "react";
import PreloaderSpinner from "components/PreloaderSpinner";
import UpdateRole from "./UpdateRole";
import AddRole from "./AddRole";
import { ROLESF } from "Constaints";
import { useAuthUser } from "react-auth-kit";
import { CHECK_PERMISSIONS } from "Constaints";
import { FormControlLabel, Switch } from "@mui/material";
import usePostAxiosData from "hooks/usePostAxiosData";

const Roles = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isOpenUM, setIsOpenUM] = useState(false);
  const [closeUM, setCloseUM] = useState(false);
  const [loadingR, errorR, roles, fetchDataU] = useDataFetching(
    `${config.app.api_url}/roles`
  );

  const [loadingS, errorS, setting, fetchSettings] = useDataFetching(
    `${config.app.api_url}/settings`
  );

  const auth = useAuthUser();
  const [loadingU2, errorU2, usr, fetchDataU2] = useDataFetching(
    `${config.app.api_url}/users/${auth()?.user?.id}`
  );

  const [loadingNot, postNotify, errorNot, successMessageNot, resultNot] = usePostAxiosData(`schedulers/notify-enterprise-employees-status`, "PUT");
  const [loadingE, postE, errorE, successMessageE, resultE] = usePostAxiosData(`settings/enable-enterprise-notifier`, "PUT");
  const [loadingD, postD, errorD, successMessageD, resultD] = usePostAxiosData(`settings/disable-enterprise-notifier`, "PUT");

  useEffect(() => {
    fetchSettings()
  }, [successMessageE, successMessageD]);

  const [roleId, setRoleId] = useState(null);

  return (
    <div>
      {loadingR && <PreloaderSpinner />}
      <div className="mt-2 grid h-full grid-cols-1 gap-5">
        <div className="mt-5 flex justify-end gap-5 bg-white rounded p-3">
         
          {usr?.data?.roles.length > 0 &&
            usr?.data?.roles?.map((role) => {
              return (
                CHECK_PERMISSIONS(role?.permissions, "Ajouter un rôle") && (
                  <>
                    {setting ? <FormControlLabel
                    
                      control={<Switch checked={setting.data.activeAutoEnterpriseNotifier}
                        onChange={(e) => (e.target.checked ? postE() : postD())

                        }
                      />} label="Active Notifier"

                    /> : <p>Settings...</p>}

                    <Button onClick={() => postNotify()}
                      isDisabled={ setting.data.activeAutoEnterpriseNotifier === false}
                      isLoading={loadingNot}
                      colorScheme="red"
                      >
                      <MdMail className="h-6 w-6" />
                      Notify enterprise now
                    </Button>
                    <Button onClick={() => onOpen()} colorScheme="blue" >
                      <MdAdd className="h-6 w-6" />
                      Ajouter un role
                    </Button>
                  </>
                )
              );
            })}
        </div>
        {/* <div>
          <label
            className={`ml-3 text-3xl font-bold text-navy-700 dark:text-white`}
          >
            Liste des roles
          </label>
        </div> */}
        <Table variant="simple" className="bg-white">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Nom</Th>
              <Th>Permission(s)</Th>
              <Th>Option</Th>
            </Tr>
          </Thead>
          <Tbody>
            {/* {console.log("roles", roles)} */}
            {roles?.data &&
              roles?.data?.map((role, index) => {
                return (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{role?.name}</Td>
                    <Td>
                      <div
                        className="me-2 overflow-auto"
                        style={{ maxHeight: "40vh" }}
                      >
                        {role?.permissions &&
                          role?.permissions?.length === 0 ? (
                          <span className="flex justify-center text-orange-500">
                            Aucune permission assignée
                          </span>
                        ) : (
                          role?.permissions
                            .sort((a, b) => {
                              return b?.name > a?.name ? -1 : 1;
                            })
                            .map((permission) => {
                              // console.log(
                              //   permission?.name === "" ? "vide" : "non vide"
                              // );
                              return (
                                <ul key={permission?.id}>
                                  <li className="py-1">
                                    {permission?.name ? (
                                      <i className="font-medium-400 text-gray-750 text-sm">
                                        {permission?.name}
                                      </i>
                                    ) : (
                                      <span className="font-medium-400 text-gray-750 text-sm">
                                        //
                                      </span>
                                    )}
                                  </li>
                                </ul>
                              );
                            })
                        )}
                      </div>
                    </Td>
                    <Td>
                      <div className="flex justify-between">
                        <div>
                          {usr?.data?.roles.length > 0 &&
                            usr?.data?.roles?.map((roleP) => {
                              return (
                                CHECK_PERMISSIONS(
                                  roleP?.permissions,
                                  "Modifier les informations d'un rôle"
                                ) && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="h-6 w-6 text-orange-400"
                                    role="button"
                                    onClick={() => {
                                      setIsOpenUM(true);
                                      setRoleId(role?.id);
                                    }}
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                    />
                                  </svg>
                                )
                              );
                            })}
                        </div>
                        {/* <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="h-6 w-6 text-red-400"
                                  role="button"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </div> */}
                      </div>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </div>

      {/* Modal création d'un user */}
      <AddRole isOpen={isOpen} onClose={() => { onClose() }} getRoles={fetchDataU} />

      {/* Modification des informations du role */}
      <UpdateRole
        fetchData={fetchDataU}
        isOpen={isOpenUM}
        setIsOpen={setIsOpenUM}
        onClose={closeUM}
        roleId={roleId}
      />
    </div>
  );
};

export default Roles;
